import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import StyledBox from 'views/common/components/UI/OffRoadDamageWaiverTable/OffRoadDamageWaiverTable.styles';
import { useTranslation } from 'next-i18next';
import useAppNavigator from 'views/common/hooks/useAppNavigator';
import PATHS from 'lib/navigation/paths';

const OffRoadDamageWaiverTable = (props) => {
  const { className } = props;
  const appNavigator = useAppNavigator();
  const { t } = useTranslation('fe_er_common_off_road_damagewaiver_table');
  const createData = (desc, coverage, vip) => ({
    desc,
    coverage,
    vip,
  });

  const rows = [
    createData(
      `${t(
        'fe_er_common_off_road_damagewaiver_table:damage_waiver',
      )} <a class="DWTLink" target='_blank' href=${appNavigator.getUrl(
        undefined,
        PATHS.tour.offRoadTourTerms().url,
        true,
      )}>${t(
        'fe_er_common_off_road_damagewaiver_table:temrs_and_conditions',
      )}</a>`,
      t('fe_er_common_off_road_damagewaiver_table:full_value_damages'),
      t('fe_er_common_off_road_damagewaiver_table:deductible_2000'),
    ),
    createData(
      t('fe_er_common_off_road_damagewaiver_table:security_deposit_strong'),
      '<strong>$2,000</strong ',
      '<strong>$100</strong ',
    ),
  ];

  return (
    <StyledBox className={className}>
      <TableContainer>
        <Table className="RPTable">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography
                  variant="body2"
                  component="p"
                  dangerouslySetInnerHTML={{
                    __html: t(
                      'fe_er_common_off_road_damagewaiver_table:damagewaiver',
                    ),
                  }}
                />
              </TableCell>
              <TableCell>
                <Typography
                  variant="body2"
                  component="p"
                  dangerouslySetInnerHTML={{
                    __html: t(
                      'fe_er_common_off_road_damagewaiver_table:no_damage_purchase',
                    ),
                  }}
                />
              </TableCell>
              <TableCell>
                <Typography
                  variant="body2"
                  component="p"
                  dangerouslySetInnerHTML={{
                    __html: t(
                      'fe_er_common_off_road_damagewaiver_table:vip_vacation',
                    ),
                  }}
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.desc}>
                <TableCell className="RPTRowTh" component="th" scope="row">
                  <Typography
                    className="ORDWTText"
                    variant="body2"
                    component="p"
                    dangerouslySetInnerHTML={{ __html: row.desc }}
                  />
                </TableCell>

                <TableCell>
                  <Typography
                    className="ORDWTText"
                    variant="body2"
                    component="p"
                    dangerouslySetInnerHTML={{ __html: row.coverage }}
                  />
                </TableCell>

                <TableCell>
                  <Typography
                    variant="body2"
                    component="p"
                    dangerouslySetInnerHTML={{ __html: row.vip }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Typography
        component="p"
        variant="h6"
        px={0.5}
        className="CRTableCaption">
        {t('fe_er_common_off_road_damagewaiver_table:damage_waiver_note')}
      </Typography>
    </StyledBox>
  );
};

export { OffRoadDamageWaiverTable };
