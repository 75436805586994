import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import Skeleton from '@mui/material/Skeleton';

const StyledBox = styled(Box)(({ theme }) => ({
  '& .cardSkeletonWrap': {
    border: `1px solid ${theme.palette.grey[100]}`,
  },
}));

const BikeCardSkeletonV2 = () => (
  <StyledBox className="cardSkeletonWrap">
    <Skeleton variant="rectangular" height={190} />
    <div className="footer">
      <Skeleton width="30%" />
      <Skeleton width="50%" />
      <br />
      <Skeleton />
      <Skeleton />
    </div>
  </StyledBox>
);
export { BikeCardSkeletonV2 };
