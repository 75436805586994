import React from 'react';
import { Box, Button } from '@mui/material';
import StyledPaper from 'views/common/components/UI/CancellationBox/CancellationBox.styles';
import { useTranslation } from 'next-i18next';
import CancellationArrow from 'views/common/components/UI/Icons/CancellationArrow';
import Typography500 from 'views/common/components/UI/Typography500';

const CancellationBox = (props) => {
  const { t } = useTranslation('fe_er_common_cancellation_box');

  const { color, text, handleViewCancellationPolicy } = props;
  return (
    <StyledPaper elevation={0} variant="outlined" square>
      <CancellationArrow className="CBIcon" color={color} />
      <Box className="CBContent">
        <Typography500 mr={1}>{text}</Typography500>
        <Button className="CBAction" onClick={handleViewCancellationPolicy}>
          {t('fe_er_common_cancellation_box:view_cancellation_policy')}
        </Button>
      </Box>
    </StyledPaper>
  );
};

export { CancellationBox };
