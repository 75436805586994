import React from 'react';
import { Skeleton, Paper } from '@mui/material';

const CancellationBoxSkeleton = () => (
  <Paper elevation={0} variant="outlined" square sx={{ px: 2.5, py: 1.5 }}>
    <Skeleton variant="text" width="90%" height={35} />
  </Paper>
);

export { CancellationBoxSkeleton };
