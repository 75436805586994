import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const CancellationArrow = (props) => (
  <SvgIcon
    {...props}
    width="20"
    height="16"
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.25 15.5834L5.125 8.00008L0.25 0.416748H14.3875L19.75 8.00008L14.3875 15.5834H0.25Z"
      fill="#4CAF50"
    />
  </SvgIcon>
);

export default CancellationArrow;
