import { Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledPaper = styled(Paper)(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(2, 2.5),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(1, 2.5),
  },
  '& .CBIcon': {
    marginRight: theme.spacing(1.5),
    marginTop: theme.spacing(0.4),
    fontSize: theme.typography.pxToRem(20),
    '& path': {
      fill: 'inherit',
    },
  },
  '& .CBContent': {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    '& .CBAction': {
      textDecoration: 'underline',
      padding: 0,
    },
  },
}));

export default StyledPaper;
