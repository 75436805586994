import { Card } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledCard = styled(Card)(({ theme, soldout }) => ({
  '&.BCcard': {
    height: '100%',
    boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.16)',
    position: 'relative',
    opacity: soldout ? 0.4 : 1,
    '& .textTransformUppercase': {
      textTransform: 'uppercase',
    },
    '& .MuiCardActionArea-root': {
      height: '100%',
      '& .MuiCardActionArea-focusHighlight': {
        display: 'none',
      },
    },
    '& .BCOldPrice': {
      textDecoration: 'line-through',
      color: theme.palette.grey[300],
      fontSize: theme.typography.pxToRem(14),
      paddingRight: theme.spacing(0.5),
    },
    '& .BCHeader': {
      // minHeight: theme.spacing(5),
      position: 'relative',
    },
    '& .BCBikeSpecs ': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(0.75, 0.5),
      borderRadius: theme.spacing(2),
      width: theme.spacing(4),
      height: theme.spacing(4),
      backgroundColor: theme.palette.grey.A000,
      color: theme.palette.grey[900],
      transition: 'border-color 0.5s, width 0.5s',
      border: `1px solid ${theme.palette.grey.A000}`,
      position: 'absolute',
      zIndex: 2,
      right: theme.spacing(1),
      top: theme.spacing(1),
      '&:hover': {
        border: `1px solid ${theme.palette.grey['100']}`,
        width: `calc(100% - ${theme.spacing(2)})`,
        '& .BCBikeSpecsText': {
          padding: theme.spacing(0, 2, 0, 1.25),
          textDecoration: 'underline',
          textTransform: 'uppercase',
        },
      },
      '& .BCBikeSpecsIcon': {
        fontSize: theme.typography.pxToRem(22),
      },
      '& .BCBikeSpecsText': {
        transition: 'padding 0.5s, text-decoration 0.5s',
        fontSize: theme.typography.pxToRem(12),
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        color: theme.palette.grey[900],
      },
    },

    '& .BClinkStyle': {
      height: '100%',
      color: 'initial',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },

    '& .BCfontsmall': {
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(16),
    },
    '& .BCLocation': {
      paddingRight: theme.spacing(2),
      // maxWidth: 'calc(100% - 130px)',
      '& svg': {
        paddingRight: theme.spacing(1),
        verticalAlign: 'middle',
        height: theme.spacing(2),
        minWidth: theme.spacing(3),
      },
    },
    '& .BCvalueDeal': {
      borderRadius: theme.spacing(10),
      padding: theme.spacing(0.2, 1, 0),
      backgroundColor: theme.palette.info.main,
      color: theme.palette.common.white,
      height: theme.spacing(2.5),
    },
    '& .noMotorcycleLicense': {
      backgroundColor: theme.palette.grey.A000,
      border: `1px solid ${theme.palette.grey['100']}`,
      borderRadius: theme.spacing(10),
      padding: theme.spacing(0.1, 1),
    },

    '& .BCHurry': {
      color: theme.palette.error.main,
      display: 'flex',
      alignItems: 'center',
      letterSpacing: 0.105,
      '& .BCHurryIcon': {
        fontSize: theme.typography.pxToRem(14),
        marginRight: theme.spacing(0.5),
      },
    },
    '& .BCsoldout': {
      color: theme.palette.grey[900],
      fontSize: theme.typography.pxToRem(14),
      textAlign: 'center',
      '& svg': {
        fontSize: theme.typography.pxToRem(18),
        marginRight: theme.spacing(0.5),
        verticalAlign: 'middle',
      },
    },
    '& .BCcontent': {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      // height: 'calc(100% - 200px)', // 200px is image height
      // paddingRight: credit ? theme.spacing(2) : theme.spacing(0),
      padding: theme.spacing(1, 2, 2),
    },
    '& .BCAccordion': {
      boxShadow: 'none',
      borderRadius: 0,
      zIndex: 1,
      position: 'absolute',
      '&.Mui-expanded': {
        minHeight: theme.spacing(2.5),
        backgroundColor: theme.palette.grey.A000,
      },
      '& .BCAccordionSummary': {
        color: theme.palette.error.main,
        backgroundColor: theme.palette.grey.A000,
        width: 'fit-content',
        padding: theme.spacing(0, 2),
        minHeight: 'initial',
        '& .MuiAccordionSummary-content': {
          margin: 0,
        },
        '& .MuiAccordionSummary-expandIconWrapper': {
          marginLeft: theme.spacing(1),
        },
        '& .BCExpandIcon': {
          color: theme.palette.grey[900],
          fontSize: theme.typography.pxToRem(18),
        },
      },
      '& .BCAccordionDetails': {
        backgroundColor: theme.palette.grey.A000,
        color: theme.palette.grey[900],
        padding: theme.spacing(1, 2),
        borderTop: `1px solid ${theme.palette.grey['100']}`,
        '& .BCfontsmall': {
          lineHeight: theme.spacing(2),
          letterSpacing: theme.typography.pxToRem(0.4),
        },
      },
    },
    '& .BCContentCont': {
      height: '100%',
    },
    '& .BCContent': {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    '& .BCBYtext': {
      fontWeight: 300,
      fontSize: theme.spacing(1.5),
      color: theme.palette.secondary.dark,
    },
    '& .BCDisplayName': {
      fontWeight: 400,
      color: theme.palette.secondary.dark,
    },
  },
  '& .BCCardActions': {
    padding: theme.spacing(0, 2, 2),
    '& .BCnDailyPriceText': {
      letterSpacing: 1.5,
      fontSize: theme.typography.pxToRem(10),
      lineHeight: theme.typography.pxToRem(10),
    },
    '& .BCCardActionsDivider': {
      margin: theme.spacing(0, 2),
      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(0, 1),
      },
    },
    '& .BCnDailyPrice': {
      color: theme.palette.secondary.main,
      width: '100%',
    },
    '& .BCJoinLink': {
      textDecoration: 'underline',
    },
    '& .BCClubPrice': {
      backgroundColor: theme.palette.primary.A000,
      padding: theme.spacing(1),
      overflow: 'hidden',
      position: 'relative',
      textAlign: 'center',
      '& .BCDayText': {
        fontSize: theme.typography.pxToRem(12),
        paddingLeft: theme.spacing(0.5),
        lineHeight: theme.typography.pxToRem(10),
      },
    },
  },
  '& .BCmodal': {
    fontSize: theme.spacing(2.25),
  },
}));

export default StyledCard;
