import React from 'react';
import {
  CardContent,
  Grid,
  Box,
  Typography,
  IconButton,
  CardActions,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import Location from 'views/common/components/UI/Icons/Location';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useTranslation } from 'next-i18next';
import StyledBikeCard from 'views/common/components/UI/BikeCardV2/BikeCardV2.styles';
import Typography500 from 'views/common/components/UI/Typography500';
import { Typography700, ImgBox } from 'views/common/components';
import EagleRiderClubGrayscale from 'views/common/components/UI/Icons/EagleRiderClubGrayscale';
import NavigatorLink from 'views/common/components/Logical/NavigatorLink';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PATHS from 'lib/navigation/paths';
import { useSelector } from 'react-redux';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// import { RibbonChip } from 'views/common/components/UI/RibbonChip/RibbonChip';

const BikeCardV2 = (props) => {
  const { t } = useTranslation('fe_er_common_bike_card');
  const {
    image,
    price,
    soldout,
    hourlyPrice,
    tentative,
    valueDeal,
    vehicleModel,
    vehicleMake,
    region,
    owner,
    credit,
    url,
    availableCount,
    onClick,
    priceInCredits,
    specsPath = null,
    oldPrice,
    noMotorcycleLicense,
    onMouseEnter,
    onMouseLeave,
  } = props;
  const showMembershipPromotion = useSelector(
    (state) => state.globalData.showMembership,
  );
  const appState = useSelector((state) => state.globalData.appState);

  return (
    <StyledBikeCard
      className="BCcard"
      soldout={soldout}
      credit={credit}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}>
      <NavigatorLink
        className="BClinkStyle"
        onClick={onClick}
        {...(url ? { href: url } : {})}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          px={2}
          pt={1.25}
          mb={1}
          className="BCHeader">
          {!availableCount && !soldout && !tentative && valueDeal && (
            <Box
              display="flex"
              alignItems="center"
              className="BCvalueDeal textTransformUppercase">
              <Typography500
                className="BCfontsmall"
                component="span"
                variant="h6">
                {t('fe_er_common_bike_card:value_deal')}
              </Typography500>
            </Box>
          )}

          {noMotorcycleLicense && (
            <Box
              display="flex"
              alignItems="center"
              className="noMotorcycleLicense textTransformUppercase">
              <Typography500
                className="BCfontsmall"
                component="span"
                variant="h6">
                {t('fe_er_common_bike_card:noMotorcycleLicense')}
              </Typography500>
            </Box>
          )}
          {specsPath && (
            <IconButton
              className="BCBikeSpecs"
              onClick={(e) => e.stopPropagation()}>
              <InfoOutlinedIcon className="BCBikeSpecsIcon" />
              <Typography500
                href={specsPath}
                translate={false}
                target="_blank"
                component={NavigatorLink}
                className="BCBikeSpecsText">
                {t('fe_er_common_bike_card:view_bike_specs')}
              </Typography500>
            </IconButton>
          )}
        </Box>

        <ImgBox
          image={{
            src: image.src,
            alt: image.alt,
          }}
          imgHeight="180px"
        />
        <Box mb={1}>
          {tentative && !availableCount && (
            <Accordion
              className="BCAccordion"
              onClick={(e) => e.stopPropagation()}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className="BCExpandIcon" />}
                className="BCAccordionSummary textTransformUppercase">
                <Typography500
                  className="BCfontsmall"
                  component="span"
                  variant="h6">
                  {`${t('fe_er_common_bike_card:limited_availability')} !`}
                </Typography500>
              </AccordionSummary>
              <AccordionDetails className="BCAccordionDetails">
                <Typography className="BCfontsmall" component="span">
                  {t('fe_er_common_bike_card:limited_availability_details')}
                </Typography>
              </AccordionDetails>
            </Accordion>
          )}
        </Box>
        <CardContent className="BCcontent">
          <Box mb={1}>
            {soldout && (
              <Box className="BCsoldout textTransformUppercase">
                <ErrorOutlineIcon />
                <Typography500 component="span">
                  {t('fe_er_common_bike_card:soldout')}
                </Typography500>
              </Box>
            )}
            {!!availableCount && (
              <Box className="BCHurry textTransformUppercase">
                <WhatshotIcon className="BCHurryIcon" />
                <Typography500 component="span" variant="h6">
                  {`${t('fe_er_common_bike_card:availability', {
                    count: availableCount,
                  })}`}
                </Typography500>
              </Box>
            )}
          </Box>

          <Box
            display="flex"
            justifyContent="space-between"
            {...(credit
              ? { align: 'center', spacing: '0', className: 'BCContentCont' }
              : { spacing: '2' })}>
            <Box item {...(credit ? { sm: '12', className: 'BCContent' } : {})}>
              <Box>
                <Typography
                  variant="subtitle2"
                  component="p"
                  className="BCfontsmall">
                  {vehicleMake.name}
                </Typography>
                <Typography500 variant="h5" component="p" className="BCmodal">
                  {vehicleModel.name}
                </Typography500>
              </Box>
              {credit && (
                <Box>
                  <Typography500 variant="h6" component="span">
                    {credit}
                    {' '}
                    {t('fe_er_common_bike_card:credit')}
                    /
                    {t('fe_er_common_bike_card:day')}
                  </Typography500>
                  <Typography700
                    variant="caption"
                    component="span"
                    className="BCOldPrice"
                    ml={1}>
                    {price}
                    /
                    {t('fe_er_common_bike_card:day')}
                  </Typography700>
                </Box>
              )}
            </Box>
          </Box>

          {owner && (
            <Box className="BCLocation" mt={1}>
              {region?.name && (
                <Box>
                  <Location />
                  <Typography
                    variant="subtitle2"
                    component="span"
                    className="BCfontsmall">
                    {region.name}
                  </Typography>
                </Box>
              )}
              <Box>
                <EagleRiderClubGrayscale />
                <Typography
                  variant="subtitle2"
                  component="span"
                  className="BCfontsmall">
                  {owner.display_name}
                </Typography>
              </Box>
            </Box>
          )}
        </CardContent>

        <CardActions className="BCCardActions">
          <Grid container columnSpacing={1.5} flexWrap="nowrap">
            {!credit && (
              <Grid
                item
                sm={priceInCredits && showMembershipPromotion ? 6 : 12}>
                <Box className="BCnDailyPrice">
                  {priceInCredits && showMembershipPromotion && (
                    <Typography500
                      component="span"
                      className="textTransformUppercase BCnDailyPriceText">
                      {t('fe_er_common_bike_card:price')}
                    </Typography500>
                  )}

                  <Box display="flex" flexWrap="wrap">
                    {/* need to add old price here */}
                    <Typography700 component="span" className="BCOldPrice">
                      {oldPrice}
                    </Typography700>
                    <Box display="flex" flexWrap="nowrap">
                      <Typography700 variant="subtitle1" component="span">
                        {price || hourlyPrice}
                      </Typography700>
                      <Typography variant="h6" component="span">
                        /
                        {price
                          ? t('fe_er_common_bike_card:day')
                          : t('fe_er_common_bike_card:hour')}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            )}

            {priceInCredits && showMembershipPromotion && (
              <>
                <Divider orientation="vertical" light flexItem />
                <Grid item sm={6}>
                  <Box className="BCClubPriceWrapper">
                    <NavigatorLink
                      target="_blank"
                      onClick={(e) => e.stopPropagation()}
                      href={PATHS.motorcycleClub.index()}>
                      <Typography500
                        component="span"
                        color="primary"
                        className="textTransformUppercase BCnDailyPriceText">
                        {t('fe_er_common_bike_card:clubPrice')}
                      </Typography500>
                      <Box>
                        <Typography700 color="primary" variant="subtitle1">
                          {priceInCredits}
                          {' '}
                          {appState.current_user
                            && appState.current_user.is_member && (
                              <>
                                {priceInCredits > 1
                                  ? t('fe_er_common_bike_card:credits.many')
                                  : t('fe_er_common_bike_card:credits.one')}
                              </>
                          )}
                          <Typography
                            color="primary"
                            component="span"
                            className="BCDayText">
                            /
                            {t('fe_er_common_bike_card:day')}
                          </Typography>
                        </Typography700>
                      </Box>
                      {!(
                        appState.current_user && appState.current_user.is_member
                      ) && (
                        <Typography500
                          onClick={(e) => e.stopPropagation()}
                          component="span"
                          color="primary"
                          className="textTransformUppercase BCnDailyPriceText BCJoinLink">
                          {t('fe_er_common_bike_card:join_save')}
                        </Typography500>
                      )}
                    </NavigatorLink>
                  </Box>
                </Grid>
              </>
            )}
          </Grid>
        </CardActions>
      </NavigatorLink>
    </StyledBikeCard>
  );
};

export { BikeCardV2 };
